// Main.js

import React, { useEffect } from 'react'
import logo from '../apron.svg'
import { MainHeader } from './Builders'

export const Main = () => {

    useEffect(() => {
        document.title = 'HOME - Recipe Collection'
    }, [])

    return (
        <div>
            <MainHeader>Recipe Collection</MainHeader>
            <img src={logo} className="apron-logo" alt="apron" />
        </div>
    )
}
